import { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLoad, useTranslate, useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import TabContainer from '@xFrame4/components/admin/TabContainer';
import EntityAddEditForm, { EntityAddEditFormProps } from '@xFrame4/components/admin/EntityAddEditForm';
import FieldEditor, { FieldEditorType, enumToDataSource } from '@xFrame4/components/common/FieldEditor';
import FacebookHelper from '@business/scheduler/FacebookHelper';
import FacebookPage from '@business/scheduler/FacebookPage';
import FacebookPost from '@business/scheduler/FacebookPost';
import SchedulePhase from '@business/scheduler/SchedulePhase';
import { FacebookPostTypeOfPost } from '@business/scheduler/generated/FacebookPost.generated';

interface FacebookPostAddEditFormProps extends EntityAddEditFormProps<FacebookPost>
{

}

const FacebookPostAddEditForm: FunctionComponent<FacebookPostAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<FacebookPost>(props.entity ?? new FacebookPost());
    const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);
    const [schedulePhases, setSchedulePhases] = useState<SchedulePhase[]>([]);
    const [linkInfo, setLinkInfo] = useState({ title: '', description: '', image: '' });

    useLoad(async () =>
    {
        // Load Facebook pages
        let pagesResult = await FacebookPage.manager.load({isActive: true});
        if (pagesResult != null) setFacebookPages(pagesResult.entities);
        
        // Schedule phases
        let phasesResult = await SchedulePhase.manager.load();
        if (phasesResult != null) setSchedulePhases(phasesResult.entities);
    });

    /** The link has changed. */
    const onLinkChange = async (link: string) =>
    {
        let ogData = await FacebookHelper.readFacebookOgTagsFromUrl(link);
        if (ogData != null)
        {
            setLinkInfo({
                title: ogData.ogTitle!,
                description: ogData.ogDescription!,
                image: ogData.ogImage!
            });
        }
    };

    useEffect(() => {
        if (entity?.link) onLinkChange(entity.link);
    }, [entity?.link]);

    /** Save */
    const onSave = async () =>
    {
        if (entity == null) return false; 

        if (entity.id == null)
        {   
            // Add (schedule)
            let scheduledPost = await entity?.page!.schedulePost(
                entity.typeOfPost as FacebookPostTypeOfPost,
                entity.scheduleDate!, 
                entity.phase!,
                entity.link,
                entity.photoFile,
                entity.videoFile,
                entity.message
            );

            return scheduledPost != null;
        }
        else
        {
            // Edit
            return await entity.save();
        }
    };

    /** Tab: general */
    let cmpTabGeneral = 
        <>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="typeOfPost"
                        label="Type"
                        isRequired={true}
                        isEnabled={mode == AddEditFormMode.Add}
                        dataSource={enumToDataSource(FacebookPostTypeOfPost)}
                        value={entity?.typeOfPost}
                        onValueChanged={(field, value) => updateEntityField(field, value?.value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="page"
                        label="Page"
                        isRequired={true}
                        dataSource={facebookPages}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="nameDetailed"
                        value={entity?.page}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Date}
                        field="scheduleDate"
                        label="Date"
                        isRequired={true}
                        value={entity?.scheduleDate}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Select}
                        field="phase"
                        label="Phase"
                        isRequired={true}
                        dataSource={schedulePhases}
                        dataSourceValueMember="id"
                        dataSourceDisplayMember="sequenceNo"
                        value={entity?.phase}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="link"
                        label="Link"
                        isRequired={entity?.typeOfPost == FacebookPostTypeOfPost.LINK}
                        isVisible={entity?.typeOfPost == FacebookPostTypeOfPost.LINK}
                        value={entity?.link}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="photoFile"
                        label="Photo"
                        isRequired={entity?.typeOfPost == FacebookPostTypeOfPost.PHOTO && mode == AddEditFormMode.Add}
                        isVisible={entity?.typeOfPost == FacebookPostTypeOfPost.PHOTO}
                        onValueChanged={(field, file) => updateEntityField(field, file)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.File}
                        field="videoFile"
                        label="Video"
                        isRequired={entity?.typeOfPost == FacebookPostTypeOfPost.VIDEO && mode == AddEditFormMode.Add}
                        isVisible={entity?.typeOfPost == FacebookPostTypeOfPost.VIDEO}
                        onValueChanged={(field, file) => updateEntityField(field, file)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="message"
                        label="Message"
                        value={entity?.message}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="title"
                        label="Title"
                        isEnabled={false}
                        isVisible={entity?.typeOfPost == FacebookPostTypeOfPost.LINK}
                        value={linkInfo.title}
                    />
                </Col>
                <Col lg={6}>
                    <img 
                        src={linkInfo.image}
                        className="img-fluid"
                        style={{display: entity?.typeOfPost == FacebookPostTypeOfPost.LINK ? 'block' : 'none'}}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Textarea}
                        field="description"
                        label="Description"
                        isEnabled={false}
                        isVisible={entity?.typeOfPost == FacebookPostTypeOfPost.LINK}
                        value={linkInfo.description}
                    />
                </Col>
            </Row>
        </>;
    
    /** Render */
    return (
        <>
            <EntityAddEditForm<FacebookPost>
                entity={entity}
                mode={mode}
                titleAdd={'Schedule post'}
                titleEdit={t('EDIT') + `${entity?.page?.nameDetailed} - ${entity?.id}`}
                saveButtonLabel="Schedule"
                onSave={() => onSave()}
                onClose={() => props.afterEdit?.(mode, entity)}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default FacebookPostAddEditForm;