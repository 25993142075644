import config from '@config';
import client from '@xFrame4/business/GraphQlClient';
import gql from 'graphql-tag';
import { BusinessEntityFieldType } from '@xFrame4/business/base/Constants';
import { imageInfoGraphQlDetails } from '@xFrame4/business/Constants';
import { ImageInfo } from '@xFrame4/business/Types';
import PixieGenerated, { PixieGeneratedEntityManager, PixieTypeOfContent } from './generated/Pixie.generated';
import PixieTranslation from './PixieTranslation';

export enum PixieLanguage
{
    de = 'de',
    en = 'en',
    es = 'es',
    fr = 'fr',
    hu = 'hu',
    it = 'it',
    nl = 'nl',
    pt = 'pt',
    ro = 'ro',
}

export class PixieEntityManager extends PixieGeneratedEntityManager<PixieTranslation, Pixie>
{
    constructor()
    {
        super({
            createEntity: () => new Pixie(),
            fields: [
                { name: 'thumbnail', type: BusinessEntityFieldType.Custom, isInput: false, customGraphQl: imageInfoGraphQlDetails },
                { name: 'thumbnailFile', isInFieldDetails: false, type: BusinessEntityFieldType.File },
                { name: 'facebookImage', type: BusinessEntityFieldType.Custom, isInput: false, customGraphQl: imageInfoGraphQlDetails },
            ],
            translationsCustomGraphQlFields: 'title slug description autoPostCount',
        });
    }
}

export class Pixie extends PixieGenerated<PixieTranslation>
{
    static manager: PixieEntityManager = new PixieEntityManager();

    thumbnail!: ImageInfo;
    facebookImage!: ImageInfo;  
    
    /**
     * Get the url of the pixie for the given language
     * 
     * @param language The language to get the url for.
     */
    getUrl(language: string)
    {
        let url = '';

        let translationForLanguage = this.getTranslation(language);

        if (translationForLanguage !== undefined)
        {
            url = config.appUrl + language + '/pixie/' + translationForLanguage.slug;
        }

        return url;
    }

    /**
     * Load some random pixies that exist for a given language.
     * 
     * @param language 
     * @param count 
     * @param excludeID 
     */
    static async loadRandomPixies(language: string, count: number, excludeID?: number)
    {
        let randomPixies: Pixie[] = [];

        let query = `
            query RandomPixies($language: String!, $count: Int!, $excludeID: Int) {
                randomPixies(language: $language, count: $count, excludeId: $excludeID) {
                    ...PixieDetailsFragment
            }
          }

          ${Pixie.manager.buildEntityDetailsFragment()}
        `;

        let { data } = await client.query({
            query: gql(query),
            variables: {
                language: language,
                count: count,
                excludeID: excludeID
            }
        });

        if (data.randomPixies != null)
        {
            for (let gqlPixie of data.randomPixies)
            {
                let pixie = Pixie.createFromGraphQL(gqlPixie) as Pixie;
                randomPixies.push(pixie);
            }
        }

        return randomPixies;
    }

    /**
     * Create a pixie with content type IMAGES.
     */
    static async createPixieWithImages(language: string, title: string, thumbnail: File, images: FileList, description = '')
    {
        let query = `
            mutation CreatePixieWithImages($language: String!, $title: String!, $image: Upload!, $images: [Upload]!, $description: String) {
                createPixieWithImages(language: $language, title: $title, image: $image, images: $images, description: $description) {
                    success
                    pixie {
                    ...PixieDetailsFragment
                    }
                }
            }
            ${Pixie.manager.buildEntityDetailsFragment()}
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                language: language,
                title: title,
                image: thumbnail,
                images: images,
                description: description
            }
        });

        if (data.createPixieWithImages != null && data.createPixieWithImages.success)
        {
            let pixie = Pixie.createFromGraphQL(data.createPixieWithImages.pixie) as Pixie;
            return pixie;
        }
        else
        {
            return null;
        }
    }

    /**
     * Create a pixie with content type VIDEO.
     * 
     * @param language
     * @param title
     * @param content
     * @param thumbnail
     * @param description
     */
    static async createPixieWithVideo(language: string, title: string, content: string, thumbnail: File, description = '')
    {
        let query = `
            mutation CreatePixieWithVideo($language: String!, $title: String!, $content: String!, $image: Upload!, $description: String) {
                createPixieWithVideo(language: $language, title: $title, content: $content, image: $image, description: $description) {
                    success
                    pixie {
                    ...PixieDetailsFragment
                    }
                }
            }
            ${Pixie.manager.buildEntityDetailsFragment()}
        `;
        
        let { data } = await client.mutate({
            mutation: gql(query),
            variables: {
                language: language,
                title: title,
                content: content,
                image: thumbnail,
                description: description
            }
        });

        if (data.createPixieWithVideo != null && data.createPixieWithVideo.success)
        {
            let pixie = Pixie.createFromGraphQL(data.createPixieWithVideo.pixie) as Pixie;
            return pixie;
        }
        else
        {
            return null;
        }
    }
}

export default Pixie;