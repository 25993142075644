import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import AddEditForm, { AddEditFormMode } from '@xFrame4/components/admin/AddEditForm';
import FieldEditor, { FieldEditorType, stringArrayToDataSource } from '@xFrame4/components/common/FieldEditor';
import { t } from '@xFrame4/common/Utils';
import Quote from '@business/quotes/Quote';

export interface QuoteAddBulkFormProps
{
    onClose(): void;
    onAdded(): void;
}

/**
 * Component: add quotes in bulk and generate images for them.
 */
const QuoteAddBulkForm: FunctionComponent<QuoteAddBulkFormProps> = (props) =>
{
    const [language, setLanguage] = useState<string>('');
    const [bulkQuotes, setBulkQuotes] = useState<string>('');

    /**
     * Add quotes in bulk and generate images for them.
     */
    const addBulkQuotes = async () =>
    {
        // split the bulk quotes by lines
        const quoteTexts = bulkQuotes.split('\n');

        // add each quote and generate image for it
        for (let quoteText of quoteTexts)
        {
            let processedQuoteText = quoteText.trim();
            if (processedQuoteText === '')
                continue;

            const quote = new Quote();
            quote.language = language;
            quote.text = processedQuoteText;
            await quote.save();
            await quote.generateImage();
        }

        props.onAdded();

        return true;
    }

    /** Render */
    return (
        <>
            <AddEditForm
                title="Add quotes in bulk"
                mode={AddEditFormMode.Add}
                onSave={() => addBulkQuotes()}
                onClose={() => props.onClose()}
            >
                <Row>
                    <Col lg={1}>
                        <FieldEditor
                            type={FieldEditorType.Select}
                            field="language"
                            label={t('LANGUAGE')}
                            isRequired={true}
                            dataSource={stringArrayToDataSource(Quote.quoteLanguages)}
                            value={language}
                            onValueChanged={(field, value) => setLanguage(value.value)}
                        />
                    </Col>
                    <Col lg={11}>
                        <FieldEditor
                            type={FieldEditorType.Textarea}
                            field="bulkQuotes"
                            label={t('TEXTS')}
                            isRequired={true}
                            value={bulkQuotes}
                            onValueChanged={(field, value) => setBulkQuotes(value)}
                        />
                    </Col>
                </Row>
            </AddEditForm>
        </>
    );
}

export default QuoteAddBulkForm;