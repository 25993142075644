import { gql } from '@apollo/client';
import client from '@xFrame4/business/GraphQlClient';
import FacebookUserProfileGenerated, { FacebookUserProfileGeneratedEntityManager } from './generated/FacebookUserProfile.generated';

export class FacebookUserProfileEntityManager extends FacebookUserProfileGeneratedEntityManager<FacebookUserProfile>
{
    constructor()
    {
        super({
            createEntity: () => new FacebookUserProfile(),
        });
    }
}

export class FacebookUserProfile extends FacebookUserProfileGenerated
{
    static manager: FacebookUserProfileEntityManager = new FacebookUserProfileEntityManager();

    /**
     * Log the user in and get the short lived access token.
     *
     * @returns The short lived token if FB login was successful. Else null.
     */
    getAccessToken(): Promise<string | null>
    {
        return new Promise((resolve) => {
            // Init Facebook SDK with own App ID.
            FB.init({
                appId: this.appId as string,
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v12.0',
                cookie: true,
            });

            // The page permissions
            let facebookLoginScope = 'public_profile,email,pages_show_list,pages_manage_engagement,pages_manage_metadata,pages_manage_posts,pages_read_engagement,pages_read_user_content,publish_video';

            // Try to get token from current session
            FB.getLoginStatus((result) => {
                console.log(result);

                if (result.status == 'connected')
                {
                    resolve(result.authResponse.accessToken);
                }
                else
                {
                    // Log the user in and get the token
                    FB.login((response) =>{
                        console.log(response);
                        if (response.status == 'connected')
                        {
                            resolve(response.authResponse.accessToken);
                        }
                        else
                        {
                            resolve(null);
                        }
                    }, { scope: facebookLoginScope });
                }
            });
        });
    }

    /**
     * Update the long lived access token for the user.
     * Also get the associated pages and insert or update them (update the access tokens).
     */
    async updateLongLivedAccessTokenAndUpdatePages(shortLivedAccessToken: string)
    {
        let query = `
        mutation SaveLongLivedAccessTokenAndUpdatePagesForUser($userId: Int!, $shortLivedAccessToken: String!)
        {
            saveLongLivedAccessTokenAndUpdatePagesForUser(userId: $userId, shortLivedAccessToken: $shortLivedAccessToken)
            {
                success
            }
        }
        `;
        
        let {data} = await client.mutate({
            mutation: gql(query),
            variables: {
                userId: this.user?.pk,
                shortLivedAccessToken: shortLivedAccessToken
            }
        });

        return data?.saveLongLivedAccessTokenAndUpdatePagesForUser?.success as boolean;
    }
}

export default FacebookUserProfile;