import { FunctionComponent } from 'react';
import { ImageInfo } from '@xFrame4/business/Types';
import { freshImageUrl } from '@xFrame4/common/Functions';
import SmoothImage from '@xFrame4/components/common/SmoothImage';

interface ImageCellProps
{
    image?: ImageInfo | null;
}

const ImageCell: FunctionComponent<ImageCellProps> = (props) =>
{
    /** Render */
    return (
        <>
            {props.image &&
            <a target="_blank" href={props.image.url} className="h-100 w-auto">
                <SmoothImage 
                    src={freshImageUrl(props.image.url)}
                    width={props.image.width}
                    height={props.image.height}
                    className="image-cell h-100 w-auto "
                />
            </a>
            }
        </>
    );
}

export default ImageCell;