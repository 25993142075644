'use client';
import { CSSProperties, FunctionComponent, useEffect, useRef, useState } from 'react';
import { BootstrapBreakpoint } from '@xFrame4/common/Constants';
import { freshImageUrl, isElementAboveThreshold } from '@xFrame4/common/Functions';

export type LazyLoadBreakpoint = {
    /** A Bootstrap breakpoint. */
    minBreakpoint: BootstrapBreakpoint;
    /** Should the image be loaded if the screen width is above the minBreakpoint? */
    load: boolean
}

/**
 * A candidate for the srcset attribute of an image element.
 */
export type SrcSetCandidate =
  | { src: string; width: number }    // e.g. "small.jpg 500w"
  | { src: string; density: number }; // e.g. "image@2x.jpg 2x"

/**
 * A candidate for the sizes attribute of an image element (should be used together with srcset).
 * Will result in an img size style attribute like: (max-width: 500px) 500px, (max-width: 1000px) 90vw, 100vw
 */
export type SrcSetSizeCandidate = {
    maxWidth: string; // e.g. "500px, 100vw"
    size: string;    // e.g. "500px"
}

interface SmoothImageProps
{
    /** The src attribute of the image element. */
    src?: string;
    /** The srcset attribute of the image element. Do not use the src attribute if you use this. */
    srcSet?: SrcSetCandidate[];
    /** The sizes attribute of the image element. Should be used together with srcset. Do not use the src attribute if you use this. */
    sizes?: SrcSetSizeCandidate[];
    enableLazyLoading?: boolean;
    /** How close (in pixels) the image must be to the bottom of the window to load the image. Good for offscreen images. */
    lazyLoadPixelTreshold?: number;
    /** Specify lazy load based on device screen width in Bootstrap breakpoint style. Useful for hidden images. */
    lazyLoadBreakpoints?: LazyLoadBreakpoint[];
    width?: number;
    height?: number;
    className?: string;
    style?: CSSProperties;
    alt?: string;
    /** Create an image URL that forces the browser to refresh the image each time */
    createFreshImageUrl?: boolean;
    /** Debug mode: console.log some stuff */
    debug?: boolean;
    /** Fired when the image has been loaded. */
    onLoaded?: () => void;
    onClick?: () => void;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
}

/**
* Get the image src from the props.src and modify it if necessary. 
* (for now: only the fresh image URL can be applied as a modification)
*/
function getImageSrc(imageSrc: string, createFreshImageUrl?: boolean)
{
    return createFreshImageUrl ? freshImageUrl(imageSrc) : imageSrc;
}

/**
 * Check if the element is visible on the screen (recursive check).
 */
function isElementVisible(el: HTMLElement | null): boolean
{
    if (!el) return false;

    // Check if the element is connected to the DOM
    if (!el.isConnected) return false;

    // Recursively check if the element or its ancestors are hidden
    while (el)
    {
        const style = window.getComputedStyle(el);
        if (style.display === 'none' || style.visibility === 'hidden')
        {
            return false;
        }
        el = el.parentElement;
    }
    return true;
}

/**
 * Renders an image that loads smoothly, lazily and responsively.
 * 
 * @param props 
 */
const SmoothImage: FunctionComponent<SmoothImageProps> = (props) =>
{
    // Default props
    const lazyLoadPixelTreshold = props.lazyLoadPixelTreshold ?? 30;

    // The srcset and sizes as strings
    let srcSet = '';
    if (props.srcSet)
    {
        let srcSetCandidates = props.srcSet.map(candidate =>
        {
            if ('width' in candidate)
            {
                return `${candidate.src} ${candidate.width}w`;
            }
            else
            {
                return `${candidate.src} ${candidate.density}x`;
            }
        });
        srcSet = srcSetCandidates.join(', ');
    }
    
    let sizes = '';
    if (props.sizes)
    {
        sizes = props.sizes.map(candidate => `(max-width: ${candidate.maxWidth}) ${candidate.size}`).join(', ');
    }

    // States
    const img = useRef<HTMLImageElement>(null);
    const [src, setSrc] = useState<string>(props.enableLazyLoading ? '' : getImageSrc(props.src ?? '', props.createFreshImageUrl)); // let the src be empty if lazy loading is enabled
    const [triggerLazyLoading, setTriggerLazyLoading] = useState<boolean>(!props.enableLazyLoading);
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
    const [isImageIntersecting, setIsImageIntersecting] = useState<boolean>(false);

    /** If props.src changes: the src state will only change if the previous src has already been loaded. */
    useEffect(() =>
    {
        if (isImageLoaded) setSrc(getImageSrc(props.src ?? '', props.createFreshImageUrl))
    }, [props.src]);

    /** Check if the HTMLImageElement has been loaded. Fires the onLoaded callback if the image is loaded. */
    useEffect(() =>
    {
        if (img.current?.complete === true)  
        {
            setIsImageLoaded(true);
            if (props.onLoaded) props.onLoaded();
        }
    }, [img.current?.complete])

    //#region Lazy loading
    // /** Load the lazy image when the image is intersecting and the element becomes visible. */
    useEffect(() =>
    {
        if (props.debug)
        {
            console.log(`isImageIntersecting changed for ${props.src}: ${isImageIntersecting}`);
            console.log(`isElementVisible when isImageIntersecting changed for ${props.src}: ${isElementVisible(img.current)}`);
        }

        if (props.enableLazyLoading && isImageIntersecting && isElementVisible(img.current))
        {
            setTriggerLazyLoading(true);
        }
    }, [isImageIntersecting]);

    /** Observe visibility using IntersectionObserver and update isImageIntersecting. */
    useEffect(() => {
        if (!props.enableLazyLoading || !img.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => setIsImageIntersecting(entry.isIntersecting),
            { rootMargin: `${lazyLoadPixelTreshold}px` } // Set threshold margin for lazy loading
        );

        if (img.current) {
            observer.observe(img.current);
        }

        return () => {
            if (img.current) {
                observer.unobserve(img.current);
            }
        };
    }, [img.current]);

    /** Load the image if lazy loading is enabled and the lazy load breakpoint settings enable it. */
    useEffect(() =>
    {
        if (props.lazyLoadBreakpoints != undefined && props.lazyLoadBreakpoints.length > 0)
        {
            // Get the screen width
            let windowWidth = window.innerWidth;

            // Get all breakpoints that are less than the screen width and sort them ASC
            let lazyLoadBreakpoints = props.lazyLoadBreakpoints
                .sort((a, b) => a.minBreakpoint - b.minBreakpoint)
                .filter(bp => bp.minBreakpoint <= windowWidth);

            // If the largest filtered breakpoint enables the lazy loading: trigger the lazy loading
            if (lazyLoadBreakpoints.length > 0 && lazyLoadBreakpoints[lazyLoadBreakpoints.length - 1].load && isElementVisible(img.current))
            {
                setTriggerLazyLoading(true);
            }
        }
    }, []);

    /** Trigger the lazy loading, set the src from the img. */
    useEffect(() =>
    {
        if (triggerLazyLoading) setSrc(getImageSrc(props.src ?? '', props.createFreshImageUrl));
    }, [triggerLazyLoading]);
    //#endregion

    /** Render */
    return (
        <img
            src={src != '' ? src : undefined}
            srcSet={srcSet != '' ? srcSet : undefined}
            sizes={sizes != '' ? sizes : undefined}
            style={{
                ...props.style,
                transition: 'opacity',
                transitionDuration: props.enableLazyLoading ? '100ms' : '0s',
                opacity: !props.enableLazyLoading ? 1 : (isImageLoaded ? 1 : 0)
            }}
            className={'smooth-image ' + (props.className ?? '')}
            ref={img}
            width={props.width}
            height={props.height}
            alt={props.alt ?? ''}
            onClick={props.onClick}
            onMouseEnter={props.onMouseEnter}
            onMouseLeave={props.onMouseLeave}
        />
    );
}

export default SmoothImage;