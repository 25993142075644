import { FunctionComponent, useEffect } from 'react';
import { LayoutColor, MenuItemType, SideBarTheme } from '@components/_admin/hyper/appConstants';
import { VerticalLayout } from '../hyper/layouts';
import { useAppActionDispatch } from '@redux/hooks';
import { setDynamicMenuItems } from '@redux/layoutSlice';

interface CustomAdminLayoutProps
{

}

const CustomAdminLayout: FunctionComponent<CustomAdminLayoutProps> = (props) =>
{
    const dispatch = useAppActionDispatch();
    
    /** Load the menu items based on database data */
    const loadDynamicMenuItems = async () =>
    {
        let menuItems: MenuItemType[] = [];

        dispatch(setDynamicMenuItems(menuItems));
    }

    useEffect(() => {
        loadDynamicMenuItems();
    }, []);
    
    /** Render */
    return (
        <>
            <VerticalLayout 
                leftSideBarTheme={SideBarTheme.LEFT_SIDEBAR_THEME_DEFAULT} 
            />
        </>
    );
}

export default CustomAdminLayout;